// import { loadManifest } from '@angular-architects/module-federation';
import { loadManifest, setManifest } from '@angular-architects/module-federation';
import { environment } from './environments/environment';


// PREVENT MODULES FROM BEING PRELOADED
const stopPreloadModules = true;
// USES ANGULAR ENVIRONMENT FILES TO DRIVE ROUTES/MODULES
const composedManifest = Object.assign({ "remoteEntry": "" }, environment.routes);
// @ts-ignore
setManifest(composedManifest, stopPreloadModules);


// IF YOU PREFER TO LOAD THE ROUTES/MODULES FROM A LOCAL ASSET FILE
// FEEL FREE TO USE THE SOLUTION BELOW
// loadManifest("/assets/routes.manifest.json", stopPreloadModules)
//    .catch(err => console.error(err))
//    .then(_ => import('./bootstrap'))
//    .catch(err => console.error(err));


import('./bootstrap').catch(err => console.error(err));
